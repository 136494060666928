<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <v-card class="pa-4" v-if="step === 1 || step === 2" outlined>
          <phone-field
            :readonly="step === 2"
            v-if="step === 1 || step === 2"
            v-model="countryPhone"
          ></phone-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="sendOTP" outlined color="primary" v-if="step === 1"
              >Send OTP</v-btn
            >
          </v-card-actions>

          <v-otp-input
            length="4"
            v-if="step === 2"
            v-model="otp"
            @finish="onOTPFinish"
          ></v-otp-input>
        </v-card>
        <v-card v-if="step === 3" class="pa-4" outlined>
          <v-text-field
            label="Set New Password"
            type="password"
            v-model="new_password"
          ></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="setPassword" outlined
              >Set Password</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PhoneField from "../components/global/PhoneField.vue";
export default {
  components: { PhoneField },
  data: () => ({
    countryPhone: "",
    otpSent: false,
    step: 1,
    token: "",
    otp: "",
    showSnackbar: false,
    snackbarText: "",
    snackbarColor: "",
    new_password: "",
    setting: [],
  }),
  mounted() {
    return this.$api.get("/settings/").then((r) => {
      this.setting = r.data.results[0];
    });
  },
  methods: {
    setPassword() {
      return this.$axios
        .post("/users/reset-password/", {
          new_password: this.new_password,
          token: this.token,
        })
        .then(() => {
          this.showSnackbar = true;
          this.snackbarText = "Password Reset Successfully";
          this.snackbarColor = "success";
        })
        .then(() => {
          this.$router.push({ name: "Login" });
        });
    },

    onOTPFinish(otp) {
      if (this.setting.allow_sms) {
        return this.$axios
          .post("/alert/otp/verify-otp/", {
            phone: this.countryPhone.phone,
            otp: otp,
          })
          .then((res) => {
            this.token = res.data.token;
            this.step = 3;
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarText = err.response.data.message;
            this.snackbarColor = "error";
          });
      } else {
        return this.$axios
          .post("/otp_mail/verify_password/", {
            phone: this.countryPhone.phone,
            otp: otp,
          })
          .then((res) => {
            this.token = res.data.token;
            this.step = 3;
            console.log("token " + this.token);
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarText = err.response.data.message;
            this.snackbarColor = "error";
          });
      }
    },

    sendOTP() {
      if (this.setting.allow_sms && this.setting.allow_mail) {
        return this.$axios
          .post("/alert/otp/send-otp/", {
            phone: this.countryPhone.phone,
          })
          .then((res) => {
            this.step = 2;
            this.showSnackbar = true;
            this.snackbarText = "OTP Sent";
            this.snackbarColor = "success";
          })
          .then(() => {
            return this.$axios
              .post("/otp_mail/password_reset/", {
                phone: this.countryPhone.phone,
              })
              .then((res) => {
                this.step = 2;
                this.showSnackbar = true;
                this.snackbarText = "OTP Sent";
                this.snackbarColor = "success";
              })
              .catch((err) => {
                this.showSnackbar = true;
                this.snackbarText = err.response.data.message;
                this.snackbarColor = "error";
              });
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarText = err.response.data.message;
            this.snackbarColor = "error";
          });
      } else if (this.setting.allow_sms) {
        return this.$axios
          .post("/alert/otp/send-otp/", {
            phone: this.countryPhone.phone,
          })
          .then((res) => {
            this.step = 2;
            this.showSnackbar = true;
            this.snackbarText = "OTP Sent";
            this.snackbarColor = "success";
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarText = err.response.data.message;
            this.snackbarColor = "error";
          });
      } else {
        return this.$axios
          .post("/otp_mail/password_reset/", {
            phone: this.countryPhone.phone,
          })
          .then((res) => {
            this.step = 2;
            this.showSnackbar = true;
            this.snackbarText = "OTP Sent";
            this.snackbarColor = "success";
          })
          .catch((err) => {
            this.showSnackbar = true;
            this.snackbarText = err.response.data.message;
            this.snackbarColor = "error";
          });
      }
    },
  },
};
</script>

<style>
</style>